.bg {
  background-color: #191fcb;
}

.logo-img {
  width: 58%;
}
.error_show {
  border: 1px solid red;
  background-color: #ffe6e6;
}
.example {
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
}
.example_new {
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
}

.cursor_pointer {
  cursor: pointer;
}

.image-container {
  position: relative;
  display: inline-block;
}

.enlarged-image-container {
  /* Define styles for the enlarged image container */
}

.limited-paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  max-height: 2em;
  line-height: 1em;
  -webkit-box-orient: vertical;
}

.whatsapp_float {
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  left: 16px;
}

.whatsapp_float_btn {
  border-radius: 63%;
  height: 60px;
  width: 60px;
}

.show-menu {
  display: none;
}

.css-o69gx8-MuiCardMedia-root {
  width: none;
}

.head1 {
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
}
.head2 {
  color: white;
  font-weight: 100;
  font-size: 11px;
  line-height: 24px;
}

.bg1 {
  background: linear-gradient(45deg, #17449e, #000000cf);
}
.bg2 {
  background-color: aliceblue;
}

.serchsugist {
  display: block;
  position: absolute;
  z-index: 9999;
  border: 1px solid #2f415d;
  background-color: #fff;
  width: 60%;
  padding: 0.5%;
  border-radius: 4px;
  max-height: 300px;
  overflow: scroll;
}

.mobileVersion {
  display: none;
}



@media only screen and (max-width: 768px) {
  .desktopVersion {
    display: none;
  }
  .mobileVersion {
    display: block;
  }
}
.grid-products .item .product-box {
  max-height: 350px;
  display: flex;
  flex-direction: column;
}
.grid-products .item .product-image {
  max-height: 60%;
}
.grid-products .item .product-image img {
  min-height: 220px !important;
  object-fit: cover;
}

@media screen and (min-width: 268px) and (max-width: 768px) {

  .header-1 .logo img {
    max-width: 120px;
  
  }
  .wishlist-link,
  .header-cart,
  .search-parent {
    display: none;
  }
  .grid-products .item .product-box {
    max-height: 350px;
    display: flex;
    flex-direction: column;
  }
  .grid-products .item .product-image {
    max-height: 60%;
  }
  .grid-products .item .product-image img {
    min-height: 220px !important;
    object-fit: cover;
    width: auto;
  }

  .input-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .form .minisearch {
    width: 100%;
    height: 100%;
    background-color: blue;
  }
  .input-box input {
    height: 22px;
  }


}
